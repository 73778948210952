/* eslint-disable no-console */
import { useEffect } from "react";
import type { GetStaticPaths, GetStaticProps } from "next";
import NotFound from "../NotFound";
import Layout from "../Layout";
import type { StaticPath } from "@sitecore-jss/sitecore-jss-nextjs";
import { SitecoreContext, ComponentPropsContext } from "@sitecore-jss/sitecore-jss-nextjs";
import { handleEditorFastRefresh } from "@sitecore-jss/sitecore-jss-nextjs/utils";
import type { SitecorePageProps } from "lib/page-props";
import { sitecorePagePropsFactory } from "lib/page-props-factory";
import { sitemapFetcher } from "lib/sitemap-fetcher";
import { componentBuilder } from "temp/componentBuilder";

const SitecorePage = ({ notFound, componentProps, layoutData, headLinks }: SitecorePageProps) => {
    useEffect(() => {
        // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
        handleEditorFastRefresh();
    }, []);

    if (notFound || !layoutData.sitecore.route) {
        // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
        return <NotFound />;
    }

    const isEditing = layoutData.sitecore.context.pageEditing;
    const vercelDomain = process.env.VERCEL_DOMAIN_URL ?? process.env.PUBLIC_URL ?? "";

    return (
        <ComponentPropsContext value={componentProps}>
            <SitecoreContext
                componentFactory={componentBuilder.getComponentFactory({ isEditing })}
                layoutData={layoutData}
            >
                <Layout layoutData={layoutData} vercelDomain={vercelDomain} headLinks={headLinks} />
            </SitecoreContext>
        </ComponentPropsContext>
    );
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (context) => {
    // Fallback, along with revalidate in getStaticProps (below),
    // enables Incremental Static Regeneration. This allows us to
    // leave certain (or all) paths empty if desired and static pages
    // will be generated on request (development mode in this example).
    // Alternatively, the entire sitemap could be pre-rendered
    // ahead of time (non-development mode in this example).
    // See https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration

    let paths: StaticPath[] = [];
    let fallback: boolean | "blocking" = "blocking";

    if (process.env.NODE_ENV !== "development" && !process.env.DISABLE_SSG_FETCH) {
        try {
            // Note: Next.js runs export in production mode
            paths = await sitemapFetcher.fetch(context);
        } catch (error) {
            console.error("Error occurred while fetching static paths", error);
        }

        fallback = process.env.EXPORT_MODE ? false : fallback;
    }

    return {
        paths,
        fallback,
    };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
    const props = await sitecorePagePropsFactory.create(context);

    return {
        props,
        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every 5 seconds
        revalidate: 5, // In seconds
        notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
    };
};

export default SitecorePage;
